import React from 'react'
import Image from './Image';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';
import StrapiUrlEncoder from '../utils/StrapiUrlEncoder';

const Card = ({ data }) => {
    var date = new Date(data.Published);
    return (
        <div className="card auto-layout auto-layout-20">
            <Image source={data.Thumbnail} ratio="3-2" />
            <p className="grey">{date.toLocaleString('default', { day: "numeric" , month: 'long', year: 'numeric' })}</p>
            <h4>{data.Title}</h4>
            <div className="small">
                <p>{data.Summary} <Link className="inline-block green" to={`/news/${StrapiUrlEncoder(data.Slug)}`}>Read More</Link></p>
            </div>
        </div>
    )
}

export default Card;